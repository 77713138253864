import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import "../css/global.css";
import { motion } from "framer-motion";
import styled from "styled-components";
import Banner from "../components/banner/banner";
import InnerWrapper from "../components/inner-wrapper/inner-wrapper";
import CtaGradient from "../components/cta-gradient/cta-gradient";
import ColouredBoxList from "../components/coloured-box-list/coloured-box-list";
import SingleSlides from "../components/carousel-slides/single-slides";
import AnimatedLogo from "../components/animated-logo/animated-logo";
import Snowflakefrom from "../images/snowflake.svg";
import Microsoft from "../images/microsoft.svg";
import SaleforcePartner from "../images/SaleforcePartner-Log.svg";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  mainBlack,
  mainWhite,
  screen,
  whileInVew,
  innerWidth,
} from "../components/variables/variables";
import DiagramDesktop from "../images/svg/about-diagram-desktop.svg";
import DiagramMobile from "../images/svg/about-diagram-mobile.svg";
import CardList from "../components/card-list/card-list";

const Wrapper = styled.div`
  .intro {
    background: ${mainBlack};
    color: ${mainWhite};
    padding: 45px 0;
    position: relative;
    overflow: hidden;
    @media ${screen.xsmall} {
      padding: 90px 0;
    }

    .flex-row {
      position: relative;
      z-index: 2;
      @media ${screen.small} {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .col {
        &--left {
          margin: 0 0 30px 0;
          @media ${screen.small} {
            margin: 0;
            max-width: 500px;
            padding: 0 70px 0 0;
          }
        }
      }
    }
  }

  .featured {
    padding: 45px 0;
    @media ${screen.xsmall} {
      padding: 90px 0;
    }
  }

  .cta {
    height: 0;
    position: relative;
  }

  .process {
    background-color: ${mainBlack};
    color: ${mainWhite};
    padding: 45px 0 275px  0;
    @media ${screen.xsmall} {
      padding: 90px 0 285px 0;
    }

    .top-txt {
      max-width: 700px;
      margin: 0 0 26px 0;
      @media ${screen.xsmall} {
        margin: 0 0 40px 0;
      }
    }

    .svg {
      position: relative;
      z-index: 2;

      &--desktop {
        display: none;
        @media ${screen.xsmall} {
          display: block;
        }
      }

      &--mobile {
        margin: 35px 0 0 0;
        @media ${screen.xsmall} {
          display: none;
        }
      }
    }
  }

  .people {
    background-image: ${(props) => `url(${props.peopleBackgroundImage})`};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 80px 0 100px 0;
    position: relative;
    @media ${screen.xsmall} {
      padding: 100px 0 180px 0;
    }
    @media ${screen.small} {
      padding: 120px 0 180px 0;
    }

    &::after {
      content: "";
      background: rgba(30, 30, 30, 0.75);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    .flex-row {
      position: relative;
      z-index: 2;

      .col {
        border-radius: 10px;

        &--top {
          color: ${mainWhite};
          margin: 0 auto 30px auto;
          max-width: 670px;
          @media ${screen.xsmall} {
            margin: 0 0 45px;
          }
          @media ${screen.small} {
            margin: 0 0 60px;
          }
        }
      }
    }
  }
  .our-partnerships {
    position: relative;
    padding: 60px 30px;
    background: #3c3c3c;
    @media ${screen.xsmall} {
        padding: 110px 30px;
    }
      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, #3c3c3c 0.00%, #279c50 100.00%);
        z-index: 1;
      }
      &--content {
        max-width: ${innerWidth};
        position: relative;
        z-index: 2;
        margin: 0 auto;
        background: transparent;
        display: block;
        justify-content: space-between;
        @media ${screen.xsmall} {
            display: flex;
        }
        &--text {
            color: ${mainWhite};
            font-weight: bold;
            font-size: 35px;
            line-height: 60px;
            margin-bottom: 30px;
        }
        &--images {
            background: ${mainWhite};
            padding: 20px 30px;
            flex-direction: column;
            gap: 30px;
            display: flex;
            align-items: center;
            border-radius: 10px;
            width: fit-content;
            margin: 0 auto;
            filter: drop-shadow(0px 10px 10px rgba(0,0,0,0.16 ));
            img {
              max-height: 100px;
              max-width: 100%;
            }
            @media ${screen.small} {
                padding: 40px 45px;
                gap: 45px;
            }
            @media ${screen.medium} {
                padding: 50px 40px;
                display: flex;
                gap: 45px;
                flex-direction: row;
                align-items: center;
            }
        }
      }
  }
`;

const AboutPage = ({ data }) => {
  const {
    banner_background_image,
    banner_background_image_mobile,
    banner_title,
    banner_description,
    intro_heading,
    intro_description,
    intro_featured_list,
    cta_heading,
    cta_description,
    cta_button_label,
    people_background_image,
    people_heading,
    people_description,
    people_list,
    process_heading,
    process_description,
    title_tag,
    meta_description,
    partnerships_logo,
    partnerships_title,
  } = data.content.data;

  // repeat content from home page
  const { intro_box_list } = data.boxList.data;

  return (
    <Layout>
      <Seo
        title={title_tag}
        description={meta_description}
        image={banner_background_image.thumbnails.og.url}
      />
      <Wrapper peopleBackgroundImage={people_background_image.url}>
        <Banner
          banner_background_image={banner_background_image}
          banner_background_image_mobile={banner_background_image_mobile}
          banner_title={banner_title}
          banner_description={banner_description}
        />

        <section className="intro">
          <InnerWrapper>
            <div className="flex-row" style={{ alignItems: 'flex-start' }}>
              <motion.div {...whileInVew} className="col col--left">
                {!!intro_heading.text && (
                  <h2 className="heading">{intro_heading.text}</h2>
                )}

                <div
                  className="description"
                  dangerouslySetInnerHTML={{ __html: intro_description.html }}
                />
              </motion.div>
              <motion.div {...whileInVew} className="col col--right">
                <ColouredBoxList list={intro_box_list} isHomePage={true} />
              </motion.div>
            </div>
          </InnerWrapper>

          <AnimatedLogo className="logo-bg" />
        </section>

        <section className="featured">
          <InnerWrapper>
            <CardList items={intro_featured_list} center />
          </InnerWrapper>
        </section>


        <section className="process">
          <InnerWrapper>
            <motion.div {...whileInVew} className="top-txt">
              <h4 className="heading">{process_heading.text}</h4>
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: process_description.html }}
              />
            </motion.div>
            <div className="svg svg--desktop">
              <DiagramDesktop />
            </div>

            <div className="svg svg--mobile">
              <DiagramMobile />
            </div>
          </InnerWrapper>
        </section>
        <section className="cta">
          <CtaGradient
            cta_heading={cta_heading}
            cta_description={cta_description}
            cta_button_label={cta_button_label}
          />
        </section>

        <section className="people">
          <InnerWrapper>
            <div className="flex-row">
              <motion.div {...whileInVew} className="col col--top">
                <h5 className="heading">{people_heading.text}</h5>
                <div
                  className="description"
                  dangerouslySetInnerHTML={{ __html: people_description.html }}
                />
              </motion.div>
              <motion.div {...whileInVew} className="col">
                <SingleSlides items={people_list} />
              </motion.div>
            </div>
          </InnerWrapper>
        </section>
        <section className='our-partnerships'>
          <div className='overlay'></div>
          <div className='our-partnerships--content'>
            <div className='our-partnerships--content--text'>{partnerships_title.text}</div>
            {
              partnerships_logo && (
                <div className="our-partnerships--content--images">
                  { 
                    partnerships_logo.map((item, index) => {
                      return <img
                        key={index}
                        alt={item.logo.alt || "Portrait"}
                        src={item.logo.url}
                      />
                    })
                  }
                </div>
              )
            }
          </div>
        </section>
      </Wrapper>
    </Layout>
  );
};

export default AboutPage;

export const dataQuery = graphql`
  {
    content: prismicAboutPage {
      data {
        banner_background_image_mobile {
          url
        }
        banner_background_image {
          url
          thumbnails {
            og {
              url
            }
          }
        }
        banner_title {
          text
        }
        banner_description {
          html
        }
        intro_heading {
          text
        }
        intro_description {
          html
        }
        intro_featured_list {
          heading {
            text
          }
          description {
            html
          }
          image {
            alt
            thumbnails {
              cropped {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
          }
        }
        cta_heading {
          text
        }
        cta_description {
          html
        }
        cta_button_label
        people_background_image {
          url
        }
        people_heading {
          text
        }
        people_description {
          html
        }
        people_list {
          full_name {
            text
          }
          image {
            alt
            thumbnails {
              cropped {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
          }
          title_position
          linkedin_account_url
          twitter_account_url
          description {
            html
          }
        }
        partnerships_logo {
          logo {
            alt
            url
          }
        }
        partnerships_title{
          text
        }
        process_heading {
          text
        }
        process_description {
          html
        }
        title_tag
        meta_description
      }
    }
    boxList: prismicHomePage {
      data {
        intro_box_list {
          background_colour_on_hover
          label
          icon {
            alt
            url
          }
        }
      }
    }
  }
`;
