// currenly used on about page only
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "../../css/slick-carousel.css";
import { mainBlack, mainBlue, mainWhite, screen } from "../variables/variables";
import { GatsbyImage } from "gatsby-plugin-image";
import { FaLinkedin, FaTwitterSquare } from "react-icons/fa";
import { IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io";

const Wrapper = styled.div`
  
    @media ${screen.xsmall} {
        margin: 0 -20px;
    }
    @media ${screen.medium} {
        margin: 0 -30px;
    }

  .slide-list {
    .slick-list {
      .slick-track {
        .slick-slide {
          height: inherit !important;
          pointer-events: all !important;
        }
      }
    }

    &__each {
      @media ${screen.xsmall} {
        padding: 0 20px;
      }
      @media ${screen.medium} {
        padding: 0 30px;
      }

      .flex-row {
        padding: 20px;
        background-color: ${mainWhite};
        box-shadow: 0 3px 14px rgba(30, 30, 30, 0.15);
        border-radius: 12px;
        @media ${screen.xsmall} {
          padding: 30px;
          display: flex;
        }
      }

      .subcol {
        &--left {
          width: 50%;
          @media ${screen.xsmall} {
            width: 35%;
            padding: 0 20px 0 0;
          }

          @media ${screen.medium} {
            padding: 0 30px 0 0;
          }

          .social-list {
            margin: 11px 0 0 0;
            position: relative;
            z-index: 9;
            @media ${screen.xsmall} {
              margin: 15px 0 0 0;
            }

            &__each {
              font-size: 1.4rem;
              margin: 0 8px 0 0;
              color: ${mainBlack};
              @media ${screen.xsmall} {
                font-size: 1.6rem;
                margin: 0 11px 0 0;
              }
              @media ${screen.withCursor} {
                &:hover {
                  color: ${mainBlue};
                }
              }
            }
          }

          img {
            border-radius: 10px;
            width: 100%;
          }
        }

        &--right {
          margin: 15px 0 0 0;
          @media ${screen.xsmall} {
            margin: 0;
            width: 65%;
          }

          .subheading {
            font-size: 0.95rem;
            font-weight: 700;
            margin: 0 0 9px 0;
            text-transform: uppercase;
            display: block;
            @media ${screen.xsmall} {
              font-size: 1.1rem;
            }

            &--smaller {
              font-weight: 500;
              font-size: 0.85rem;
              @media ${screen.xsmall} {
                font-size: 1rem;
              }
            }
          }
        }
      }
    }

    .slick-dots {
      bottom: -45px;
      @media ${screen.xsmall} {
        bottom: -60px;
      }
      @media ${screen.small} {
        bottom: -75px;
      }

      li {
        margin: 0 4px;

        button {
          background: #d0cece;
          border-radius: 50%;
          width: 3px;
          height: 3px;
          transform: scale(0.75);
          box-shadow: 0 3px 10px -2px rgba(30, 30, 30, 0.7);
          @media ${screen.xsmall} {
            width: 4px;
            height: 4px;
            transform: scale(1);
          }

          ::before {
            color: #d0cece;
            opacity: 0;
          }
        }
      }

      .slick-active {
        button {
          background: ${mainWhite};
          transform: scale(1.075);
          @media ${screen.xsmall} {
            transform: scale(1.35);
          }

          ::before {
            color: ${mainWhite};
          }
        }
      }
    }

    .arrow {
      display: block;
      cursor: pointer;
      position: absolute;
      top: 48px;
      color: ${mainBlue};
      font-size: 5rem;
      @media ${screen.xsmall} {
        color: #d0cece;
        z-index: 5;
        font-size: 3.5rem;
        top: 50%;
        transform: translateY(-50%);
      }

      &--next {
        right: 0;
        @media ${screen.xsmall} {
          right: -50px;
        }
      }

      &--prev {
        display: none;
        @media ${screen.xsmall} {
          left: -50px;
          display: block;
        }
      }
    }
  }
`;

function NextArrow(props) {
  const { onClick } = props;
  return (
    <div className="arrow arrow--next" role="button" onClick={onClick}>
      <IoMdArrowDropright />
    </div>
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="arrow arrow--prev" role="button" onClick={onClick}>
      <IoMdArrowDropleft />
    </div>
  );
}

const SingleSlides = ({ items }) => {
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    const userAgent =
      typeof window.navigator === "undefined" ? "" : navigator.userAgent;
    const mobile = Boolean(userAgent.match(/Mobi|Android|BlackBerry|iPhone/i));
    setMobile(mobile);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    swipe: false,
    draggable: true,
    adaptiveHeight: isMobile,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Wrapper>
      {items && (
        <Slider className="slide-list" {...settings}>
          {items.map((slide, index) => (
            <div className="slide-list__each" key={index}>
              <div className="flex-row">
                <div className="subcol subcol--left">
                  <GatsbyImage
                    alt={slide.image.alt || "Portrait"}
                    image={slide.image.thumbnails.cropped.gatsbyImageData}
                  />
                  <div className="social-list">
                    {slide.twitter_account_url && (
                      <a
                        className="social-list__each"
                        href={slide.twitter_account_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaTwitterSquare />
                      </a>
                    )}

                    {slide.linkedin_account_url && (
                      <a
                        className="social-list__each"
                        href={slide.linkedin_account_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaLinkedin />
                      </a>
                    )}
                  </div>
                </div>
                <div className="subcol subcol--right">
                  <h6 className="subheading">{slide.full_name.text}</h6>
                  <span className="subheading subheading--smaller">
                    {slide.title_position}
                  </span>
                  <div
                    className="description"
                    dangerouslySetInnerHTML={{ __html: slide.description.html }}
                  />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      )}
    </Wrapper>
  );
};

export default SingleSlides;
