import React from "react";
import styled from "styled-components";
import {
  mainBlack,
  mainWhite,
  transHover,
  screen,
} from "../variables/variables";
import { Link } from "gatsby";
import { toSlug } from "../utils/helpers";

const Wrapper = styled.div`
  max-width: 842px;
  position: relative;
  z-index: 2;

  .item-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -6px;
    @media ${screen.xsmall} {
      margin: 0 -12px;
    }

    &__each {
      border-radius: 8px;
      width: calc(50% - 12px);
      box-shadow: 0 6px 20px -2px rgba(0, 0, 0, 0.35);
      background: ${mainBlack};
      transition: ${transHover};
      margin: 0 6px 12px 6px;
      @media ${screen.xsmall} {
        width: calc(33.33% - 24px);
        margin: 0 12px 24px 12px;
      }
      @media ${screen.small} {
        width: ${(props) =>
          props.isHomePage ? `calc(50% - 24px)` : `calc(33.33% - 24px)`};
      }
      @media ${screen.medium} {
        width: calc(33.33% - 24px);
      }

      .link-container {
        color: ${mainWhite};
        text-align: center;
        display: block;
        padding: 26px 11px;
        @media ${screen.xsmall} {
          padding: 42px 21px;
        }

        .icon {
          width: 50px;
          display: block;
          margin: 0 auto;
          transition: ${transHover};
          @media ${screen.xsmall} {
            width: 80px;
          }
        }

        .label {
          display: block;
          font-size: 0.75rem;
          font-weight: 600;
          margin: 16px 0 0 0;
          @media ${screen.xsmall} {
            font-size: 1rem;
            margin: 26px 0 0 0;
          }
        }
      }

      @media ${screen.withCursor} {
        &:hover {
          .icon {
            filter: brightness(0) invert(1);
          }
        }
      }

      &:first-child {
        @media ${screen.withCursor} {
          &:hover {
            background: ${(props) =>
              props.backgroundColours && props.backgroundColours[0]};
          }
        }
      }

      &:nth-child(2) {
        @media ${screen.withCursor} {
          &:hover {
            background: ${(props) =>
              props.backgroundColours && props.backgroundColours[1]};
          }
        }
      }

      &:nth-child(3) {
        @media ${screen.withCursor} {
          &:hover {
            background: ${(props) =>
              props.backgroundColours && props.backgroundColours[2]};
          }
        }
      }

      &:nth-child(4) {
        @media ${screen.withCursor} {
          &:hover {
            background: ${(props) =>
              props.backgroundColours && props.backgroundColours[3]};
          }
        }
      }

      &:nth-child(5) {
        @media ${screen.withCursor} {
          &:hover {
            background: ${(props) =>
              props.backgroundColours && props.backgroundColours[4]};
          }
        }
      }

      &:nth-child(6) {
        @media ${screen.withCursor} {
          &:hover {
            background: ${(props) =>
              props.backgroundColours && props.backgroundColours[5]};
          }
        }
      }

      &:nth-child(7) {
        @media ${screen.withCursor} {
          &:hover {
            background: ${(props) =>
              props.backgroundColours && props.backgroundColours[6]};
          }
        }
      }
    }
  }
`;

const ColouredBoxList = ({ list, isHomePage }) => {
  // background colour on hover - strictly for six item(see css above)
  const backgroundColours = list.map(
    (item) => item.background_colour_on_hover || mainBlack
  );

  return (
    <Wrapper backgroundColours={backgroundColours} isHomePage={isHomePage}>
      <ul className="item-list">
        {list &&
          list.map((item) => (
            <li className="item-list__each" key={item.label}>
              <Link className="link-container" to={`/${toSlug(item.label)}/`}>
                <img className="icon" src={item.icon.url} />
                <span className="label">{item.label}</span>
              </Link>
            </li>
          ))}
      </ul>
    </Wrapper>
  );
};

export default ColouredBoxList;
